import {
  OrgCoupon,
  OrgInvoice,
  OrgInvoiceId,
  OrgPaymentInvoiceCredit,
  OrgPaymentId,
  OrgPaymentPlan,
  OrgPaymentType,
  OrgInvoiceTypes,
  OrgInvoiceParent
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit, ObjectKeys } from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";
import shortid from "shortid";

export async function orgPayment__client__deleteOrgPaymentInvoiceCredits(p: { orgPaymentInvoiceCreditIds: OrgPaymentId[] }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const nowMS = Date.now();

  const orgPaymentInvoiceCredits = _.compact(await h.OrgPayment.getDocs(p.orgPaymentInvoiceCreditIds)).filter(
    op => op.type === OrgPaymentType.invoiceCredit
  ) as OrgPaymentInvoiceCredit[];

  const batchTasks: BatchTask[] = [];

  const orgInvoices = _.compact(await h.OrgInvoice.getDocs(orgPaymentInvoiceCredits.map(opc => opc.invoiceId)));
  const parentOrgInvoiceIds = _.uniq(
    _.compact(
      orgInvoices.map(oi => {
        if (
          oi.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
          oi.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
        ) {
          return oi.parentOrgInvoiceId;
        }
        return null;
      })
    )
  );
  const orgInvoiceParents = (parentOrgInvoiceIds.length ? _.compact(await h.OrgInvoice.getDocs(parentOrgInvoiceIds)) : []).filter(
    a => a.type === OrgInvoiceTypes.manual || a.type === OrgInvoiceTypes.registration
  ) as OrgInvoiceParent[];
  const orgInvoiceUpdates: Record<
    OrgInvoiceId,
    Pick<OrgInvoice, "derivedTotalAmountPaidCentsBeforeAllFees" | "thisInvoicePaidInFullDateMS">
  > = orgInvoices.reduce((acc, oi) => {
    acc[oi.id] = {
      derivedTotalAmountPaidCentsBeforeAllFees: oi.derivedTotalAmountPaidCentsBeforeAllFees,
      thisInvoicePaidInFullDateMS: oi.thisInvoicePaidInFullDateMS
    };
    return acc;
  }, {} as Record<OrgInvoiceId, Pick<OrgInvoice, "derivedTotalAmountPaidCentsBeforeAllFees" | "thisInvoicePaidInFullDateMS">>);

  const orgInvoiceParentUpdates: Record<
    OrgInvoiceId,
    Pick<OrgInvoiceParent, "derivedTotalAmountPaidCentsIncludingChildrenInvoices">
  > = orgInvoiceParents.reduce((acc, oi) => {
    acc[oi.id] = {
      derivedTotalAmountPaidCentsIncludingChildrenInvoices: oi.derivedTotalAmountPaidCentsIncludingChildrenInvoices
    };
    return acc;
  }, {} as Record<OrgInvoiceId, Pick<OrgInvoiceParent, "derivedTotalAmountPaidCentsIncludingChildrenInvoices">>);

  for (let i = 0; i < orgPaymentInvoiceCredits.length; i++) {
    const opc = orgPaymentInvoiceCredits[i];
    const orgInvoice = orgInvoices.find(oi => oi.id === opc.invoiceId);
    if (orgInvoice?.thisInvoicePaidInFullDateMS && orgInvoice.dueDateMS < Date.now()) {
      // Can't delete credit if the due date has passed and the invoice is marked as paid
      continue;
    }
    const prevInvoiceDetails = orgInvoiceUpdates[opc.invoiceId];
    const prevPaidAmount = prevInvoiceDetails.derivedTotalAmountPaidCentsBeforeAllFees;
    const newPaidAmount = Math.max(prevPaidAmount - opc.amountCents, 0);
    orgInvoiceUpdates[opc.invoiceId] = {
      derivedTotalAmountPaidCentsBeforeAllFees: newPaidAmount,
      thisInvoicePaidInFullDateMS: 0
    };
    batchTasks.push(
      await h.OrgPayment.delete(
        {
          id: opc.id
        },
        { returnBatchTask: true }
      )
    );

    if (
      orgInvoice &&
      (orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
        orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment)
    ) {
      const orgInvoiceParentUpdate = orgInvoiceParentUpdates[orgInvoice.parentOrgInvoiceId];
      if (orgInvoiceParentUpdate) {
        orgInvoiceParentUpdates[orgInvoice.parentOrgInvoiceId] = {
          derivedTotalAmountPaidCentsIncludingChildrenInvoices: Math.max(
            orgInvoiceParentUpdate.derivedTotalAmountPaidCentsIncludingChildrenInvoices - opc.amountCents,
            0
          )
        };
      }
    }
  }

  for (let j = 0; j < ObjectKeys(orgInvoiceUpdates).length; j++) {
    const invoiceId = ObjectKeys(orgInvoiceUpdates)[j];
    batchTasks.push(
      await h.OrgInvoice.update(
        {
          id: invoiceId,
          doc: {
            derivedTotalAmountPaidCentsBeforeAllFees: orgInvoiceUpdates[invoiceId].derivedTotalAmountPaidCentsBeforeAllFees,
            thisInvoicePaidInFullDateMS: orgInvoiceUpdates[invoiceId].thisInvoicePaidInFullDateMS
          }
        },
        { returnBatchTask: true }
      )
    );
  }
  for (let k = 0; k < ObjectKeys(orgInvoiceParentUpdates).length; k++) {
    const invoiceId = ObjectKeys(orgInvoiceParentUpdates)[k];
    batchTasks.push(
      await h.OrgInvoice.update(
        {
          id: invoiceId,
          doc: {
            derivedTotalAmountPaidCentsIncludingChildrenInvoices:
              orgInvoiceParentUpdates[invoiceId].derivedTotalAmountPaidCentsIncludingChildrenInvoices
          }
        },
        { returnBatchTask: true }
      )
    );
  }

  await h._BatchRunner.executeBatch(batchTasks);
}

// i18n certified - complete
