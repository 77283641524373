import { OrgInvoice, OrgInvoiceId, OrgPaymentInvoiceCredit, OrgPaymentId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { ObjectKeys, filterOrgPaymentInvoices } from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";

export async function orgPayment__client__getOrgPaymentAndAssociatedOrgInvoice(p: { orgPaymentId: OrgPaymentId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const orgPayment = await h.OrgPayment.getDoc(p.orgPaymentId);

  if (!orgPayment || !("invoiceId" in orgPayment)) {
    return null;
  }
  const orgInvoice = await h.OrgInvoice.getDoc(orgPayment.invoiceId);

  if (!orgInvoice) {
    return null;
  }
  return { orgPayment, orgInvoice };
}

// i18n certified - complete
