import {
  CalendarEntry,
  CalendarEntryGameScrimmage,
  OrgInvoice,
  OrgPayment,
  PlayerBundleId,
  SoccerStatModes
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgPayment__client__getOrgPaymentsForPlayerBundleIdsSubscription(p: { playerBundleIds: PlayerBundleId[] }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const queries: SimpleQuery<OrgPayment>[] = [];
  p.playerBundleIds.forEach(playerBundleId => {
    queries.push({
      where: [{ playerBundleId: ["==", playerBundleId] }],
      orderBy: [{ dir: "desc", pathObj: { createdAtMS: true } }]
    });
  });

  return firestoreLiftQuerySubToBifrostSub<OrgPayment>(
    h.OrgPayment.multiQuerySubscription({
      queries,
      mergeProcess: { orderBy: { sortKey: "createdAtMS", dir: "desc" } }
    }) as any
  );
}

// i18n certified - complete
