import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import {
  CCFailureCodes,
  OrgInvoiceChild,
  OrgPaymentType,
  PaymentMethodType,
  isKeyOfNMIResponseCodes
} from "@ollie-sports/models";

import express from "express";
import _ from "lodash";
import { formatMoneyCentsToDollarCentPrettyString, getRefundAvailabilityDetails, isProduction } from "../../utils";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { translateServer } from "../../utils/serverTranslate";
import axios from "axios";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { issueRefund, logUnexpectedPaymentError } from "../../utils/payment-helpers";
import { nmiSDK } from "../../utils/nmiSDK";

export async function orgPayment__server__issueRefund(
  this: ServerThisContext,
  p: {
    orgPaymentId: string;
    orgId: string;
    amountCents: number;
    reason: string;
    selfAccountId: string;
  }
): Promise<{ status: "success" } | { status: "failed"; prettyFailureReason: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  return issueRefund({ ...p, locale: this.locale });
  // SERVER_ONLY_TOGGLE
}

orgPayment__server__issueRefund.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
