import express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getServerHelpers } from "../../helpers";
import { OrgInvoiceTypes } from "@ollie-sports/models";

export async function orgPayment__client__checkIfAccountIsResponsibleForScheduled(p: { accountId: string }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const scheduledOrgInvoice = (
    await h.OrgInvoice.query({
      where: [{ accountIdScheduledToPay: ["==", p.accountId] }, { thisInvoicePaidInFullDateMS: ["==", 0] }],
      limit: 1
    })
  ).docs;

  return !!scheduledOrgInvoice.length;
}

orgPayment__client__checkIfAccountIsResponsibleForScheduled.auth = async (r: express.Request) => {
  // Make sure user has correct permission
  await validateToken(r);
};
