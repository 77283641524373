import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { OrgInvoiceChild } from "@ollie-sports/models";

import express from "express";
import _ from "lodash";
import moment from "moment";
import { getIndividualOrgInvoiceAmountDetails, isProduction } from "../../utils";
import { orgInvoice__server__payIndividualOrgInvoiceChild } from "../orgInvoice";
import { getDefaultPaymentAccount } from "../../compute/account.compute";
import { validateSelfAccountId, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function orgPayment__server__getPaymentsForParentInvoice(p: {
  selfAccountId: string;
  parentOrgInvoiceId: string;
  orgId: string;
}) {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h } = getServerHelpers();

  const [payments, org] = await Promise.all([
    h.OrgPayment.query({ where: [{ invoiceGroupId: ["==", p.parentOrgInvoiceId] }] }),
    h.Org.getDoc(p.orgId)
  ]);

  if (!org?.accounts[p.selfAccountId]?.permissions.manageFinances || !payments.docs.every(a => a.orgId === p.orgId)) {
    throw new Error("Does not have access to view payments!");
  }

  return payments.docs;

  // SERVER_ONLY_TOGGLE
}

orgPayment__server__getPaymentsForParentInvoice.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
