import { OrgId, OrgInvoiceId, OrgPaymentInvoiceCredit, OrgPaymentType } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import _ from "lodash";

export async function orgInvoice__server__getOrgPaymentInvoiceCreditsForOrgInvoiceNoAuth(p: {
  orgId: OrgId;
  orgInvoiceId: OrgInvoiceId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return (
    await h.OrgPayment.query({
      where: [
        {
          type: ["==", OrgPaymentType.invoiceCredit]
        },
        {
          orgId: ["==", p.orgId]
        },
        { invoiceId: ["==", p.orgInvoiceId] }
      ]
    })
  ).docs as OrgPaymentInvoiceCredit[];
  // SERVER_ONLY_TOGGLE
}

orgInvoice__server__getOrgPaymentInvoiceCreditsForOrgInvoiceNoAuth.auth = async (req: any) => {};

// i18n certified - complete
