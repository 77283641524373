import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { OrgInvoiceChild, OrgPaymentType } from "@ollie-sports/models";

import express from "express";
import _ from "lodash";
import { formatMoneyCentsToDollarCentPrettyString, getRefundAvailabilityDetails, isProduction } from "../../utils";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { translateServer } from "../../utils/serverTranslate";

export async function orgPayment__server__lazyCheckPendingRefundForCompletion(p: {
  orgPaymentRefundId: string;
  orgId: string;
  selfAccountId: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  try {
    const [orgPaymentRefund, org, orgSecret] = await Promise.all([
      h.OrgPaymentRefund.getDoc(p.orgPaymentRefundId),
      h.Org.getDoc(p.orgId),
      h.OrgSecret.getDoc(p.orgId)
    ]);

    if (!orgPaymentRefund) {
      return;
    }
  } catch (e) {
    return;
  }
  // SERVER_ONLY_TOGGLE
}

orgPayment__server__lazyCheckPendingRefundForCompletion.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
